<template>
  <el-card>
    <!-- 按钮区 -->
    <el-button type="primary" @click="getSalaryDetails">搜索</el-button>
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        @click="createPlanFormButton"
        >生成计划</el-button
      >
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        @click="editSumbitButtonV2"
        >调整计划</el-button
      >
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        @click="addEditSumbitButtonV2"
        >填报实际</el-button
      >
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        @click="selectByInfoButtonV2"
        >查看详情</el-button
      >
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        @click="fileDetailsButton"
        >处理归档</el-button
      >
      <el-button
        type="success"
        icon="el-icon-edit"
        size="min"
        disabled
        >更新建议</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-edit"
        size="min"
        @click="detailsButton"
        >删除</el-button
      >
    </el-row>
    <!-- 列表区域 -->
    <el-table
      :data="salaryInfoList"
      border
      stripe
      style="width: 100%"
      show-summary
      @row-dblclick="dataBackFillGoods"
    >
      <el-table-column
        label="选择"
        width="70"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-radio
            class="radio"
            v-model="questionnaireId"
            :label="scope.row.id"
            @click.native.stop.prevent="getCurrentRow(scope.row)"
            >&nbsp;</el-radio
          >
        </template>
      </el-table-column>
      <el-table-column label="处理月份" prop="recordData" />
      <el-table-column label="工资月份" prop="disposeMonth" />
      <el-table-column label="当月收入" prop="countMoney" />
      <el-table-column label="当月目标费用" prop="targetMoney" />
      <el-table-column label="当月计划费用" prop="planMoney" />
      <el-table-column label="当月总消费" prop="costMoney" />
      <el-table-column label="状态" prop="datailsStatus" />
    </el-table>
    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    <ShowCreatePlan ref="ShowCreatePlanFrom" />
    <AddSalaryDetailsV2 ref="AddSalaryDetailsV2From" />
  </el-card>
</template>

<script>
import AddSalaryDetailsV2 from "./modules/AddSalaryDetailsV2";
import ShowCreatePlan from "./modules/ShowCreatePlan";
import {
  fileDetails,
  salaryDetailsList,
  deleteDataApi,
} from "@/api/work/salary/salaryDetails";
export default {
  name: "salaryInfo",
  components: {
    ShowCreatePlan,
    AddSalaryDetailsV2,
  },
  data() {
    return {
      salaryInfoList: [],
      radio: "",
      version: "",
      questionnaireId: "",
      templateSelection: {},
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getSalaryDetails();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.getSalaryDetails();
    },
    "queryParams.pageSize"(val) {
      this.getSalaryDetails();
    },
  },
  methods: {
    getSalaryDetails() {
      salaryDetailsList(this.queryParams).then((res) => {
        this.salaryInfoList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    editSumbitButtonV2() {
      console.log(this.templateSelection);
      // 判读templateSelection不为null
      if (this.id == null || this.id === undefined)
        return this.$message.error("请选择计划");
      if (this.templateSelection.datailsStatus == 3)
        return this.$message.error("归档数据不能修改");
      this.$refs.AddSalaryDetailsV2From.show(this.templateSelection, 1);
    },
    addEditSumbitButtonV2() {
      if (this.id == null || this.id === undefined)
        return this.$message.error("请选择计划");
      if (this.templateSelection.datailsStatus == 3)
        return this.$message.error("归档数据不能修改");
      this.$refs.AddSalaryDetailsV2From.show(this.templateSelection, 2);
    },
    selectByInfoButtonV2() {
      if (this.id == null || this.id === undefined)
        return this.$message.error("请选择计划");
      this.$refs.AddSalaryDetailsV2From.show(this.templateSelection, 3);
    },

    fileDetailsButton() {
      if (this.id == null) return this.$message.error("请选择计划");
      if (this.templateSelection.datailsStatus == 3)
        return this.$message.error("已经归档的数据不能再归档");
      fileDetails({ version: this.templateSelection.version }).then((res) => {
        this.getSalaryDetails();
        this.questionnaireId = null;
        this.id = null;
      });
    },
    detailsButton() {
      if (this.id == null) return this.$message.error("请选择计划");
      deleteDataApi({ version: this.templateSelection.version }).then((res) => {
        this.getSalaryDetails();
        this.questionnaireId = null;
        this.id = null;
      });
    },

    createPlanFormButton() {
      this.$refs.ShowCreatePlanFrom.show();
    },

    dataBackFillGoods(row, event, column) {
      console.log("双击事件", row, event, column);
      this.$refs.AddSalaryDetailsV2From.show(row, 3);
    },
  },
};
</script>