<template>
  <div>
    <el-dialog
      title="调整计划"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="analyzeShowButton()"
          :disabled="type === 3"
          >保 存</el-button
        >
        <el-button type="primary" @click="cancel()">关 闭</el-button>
      </span>
      <el-divider></el-divider>
      <el-form v-model="addFrom" label-width="100px" ref="addFrom">
        <el-row :gutter="22">
          <el-col :span="10">
            <el-form-item label="工资月份" width="140px" prop="disposeMonth">
              <el-input width="20px" v-model="addFrom.disposeMonth" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-button @click="refreshDataButton()" :disabled="type === 3"
              >刷新</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="6">
            <el-form-item label="总共到手" width="120px">
              {{ this.countMoney }}</el-form-item
            >
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="计划总费用"
              width="120px"
              prop="newGeneralIncome"
              >{{ this.sumPlanMoney }}</el-form-item
            >
          </el-col>
          <el-col :span="6">
            <el-form-item label="总消费" width="120px" prop="consumeIncome">{{
              this.sumCostMoney
            }}</el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="22">
           本月事项
        </el-row> -->
      </el-form>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item name="1">
          <template slot="title">
            <el-tag>固定费用</el-tag>
            <el-divider direction="vertical" />目标: {{ this.targetFixMoney }}
            <el-divider direction="vertical" /> 建议:
            {{ this.suggestFixMoney }} <el-divider direction="vertical" /> 计划:
            {{ this.planFixMoney }} <el-divider direction="vertical" />实际:
            {{ this.costFixMoney }}
          </template>
          <el-table
            :data="fixDataList"
            border
            stripe
            :span-method="spanMethod('fixDataList')"
            ref="fixDataList"
          >
            <el-table-column prop="attributionName" label="费用类目" />
            <el-table-column prop="targetName" label="目标名称" />
            <el-table-column prop="targetMoney" label="目标费用" width="80px"/>
            <el-table-column prop="targetRemark" label="目标备注" />
            <!-- <el-table-column prop="suggestMoney" label="建议费用"  width="100px"/>
            <el-table-column prop="suggestRemark" label="建议备注" /> -->
            <el-table-column prop="planMoney" label="计划费用" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planMoney"
                  type="number"
                  size="small"
                  :disabled="type === 2 || type === 3"
                  @change="recalculate"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="planRemark" label="计划备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planRemark"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costMoney" label="实际费用" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costMoney"
                  type="number"
                  size="small"
                  :disabled="type === 1 || type === 3"
                  @change="recalculate"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costRemark" label="实际备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costRemark"
                  :disabled="type === 1 || type === 3"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <el-tag type="success">生活费用</el-tag>
            <el-divider direction="vertical" />目标: {{ this.targetLifeMoney }}
            <el-divider direction="vertical" /> 建议:
            {{ this.suggestLifeMoney }}
            <el-divider direction="vertical" /> 计划: {{ this.planLifeMoney }}
            <el-divider direction="vertical" />实际: {{ this.costLifeMoney }}
          </template>
          <el-table
            :data="lifeDataList"
            border
            stripe
            :span-method="spanMethod('lifeDataList')"
            ref="lifeDataList"
          >
            <el-table-column prop="attributionName" label="费用类目" />
            <el-table-column prop="targetName" label="目标名称" />
            <el-table-column prop="targetMoney" label="目标费用" width="80px"/>
            <el-table-column prop="targetRemark" label="目标备注" />
            <!-- <el-table-column prop="suggestMoney" label="建议费用" width="100px"/>
            <el-table-column prop="suggestRemark" label="建议备注" /> -->
            <el-table-column prop="planMoney" label="计划生活费" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planMoney"
                  type="number"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
             <el-table-column prop="planRemark" label="计划备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planRemark"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costMoney" label="实际生活费"  width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costMoney"
                  type="number"
                  size="small"
                   :disabled="type === 1 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costRemark" label="实际备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costRemark"
                  :disabled="type === 1 || type === 3"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <el-tag type="warning">提升费用</el-tag>
            <el-divider direction="vertical" />目标:
            {{ this.targetImproveMoney }}
            <el-divider direction="vertical" /> 建议:
            {{ this.suggestImproveMoney }}
            <el-divider direction="vertical" /> 计划:
            {{ this.planImproveMoney }} <el-divider direction="vertical" />实际:
            {{ this.costImproveMoney }}
          </template>
          <el-table
            :data="improveDataList"
            border
            stripe
            :span-method="spanMethod('improveDataList')"
            ref="improveDataList"
          >
            <el-table-column prop="attributionName" label="费用类目" />
            <el-table-column prop="targetName" label="计划名称" />
            <el-table-column prop="targetMoney" label="目标费用"  width="80px"/>
            <el-table-column prop="targetRemark" label="计划备注" />
            <!-- <el-table-column prop="suggestMoney" label="建议费用"  width="100"/>
            <el-table-column prop="suggestRemark" label="建议备注" /> -->
            <el-table-column prop="planMoney" label="计划生活费" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planMoney"
                  type="number"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="planRemark" label="计划备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planRemark"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costMoney" label="实际生活费" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costMoney"
                  type="number"
                  size="small"
                  :disabled="type === 1 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costRemark" label="实际备注" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costRemark"
                  :disabled="type === 1 || type === 3"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title">
            <el-tag type="info">其他费用</el-tag>
            <el-divider direction="vertical" />目标:
            {{ this.targetOtherMoney }}
            <el-divider direction="vertical" /> 建议:
            {{ this.suggestOtherMoney }}
            <el-divider direction="vertical" /> 计划: {{ this.planOtherMoney }}
            <el-divider direction="vertical" />实际: {{ this.costOtherMoney }}
          </template>
          <el-table
            :data="otherDataList"
            border
            stripe
            :span-method="spanMethod('otherDataList')"
            ref="otherDataList"
          >
            <el-table-column prop="attributionName" label="费用类目" />
            <el-table-column prop="targetName" label="计划名称" />
            <el-table-column prop="targetMoney" label="目标费用"  width="80"/>
            <el-table-column prop="targetRemark" label="计划备注" />
            <!-- <el-table-column prop="suggestMoney" label="建议费用"  width="100"/>
            <el-table-column prop="suggestRemark" label="建议备注" /> -->
            <el-table-column prop="planMoney" label="计划生活费" width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planMoney"
                  type="number"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="planRemark" label="计划备注"  width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.planRemark"
                  size="small"
                  :disabled="type === 2 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costMoney" label="实际生活费"  width="100">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costMoney"
                  type="number"
                  size="small"
                  :disabled="type === 1 || type === 3"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="costRemark" label="实际备注"  width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.costRemark"
                  :disabled="type === 1 || type === 3"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
    
<script>
import {
  add,
  selectSalaryByIdV1,
  edit,
  getPlanMoneyByMonth,
  refreshDataApi,
} from "@/api/work/salary/salaryDetails";
export default {
  data() {
    return {
      activeNames: "1",
      type: 1,
      // 目标target:100,建议 suggest:200,计划 plan:300,实际 cost:200
      fixDataList: [],
      otherDataList: [],
      improveDataList: [],
      lifeDataList: [],
      //固定
      targetFixMoney: null,
      suggestFixMoney: null,
      planFixMoney: null,
      costFixMoney: null,
      //其他
      targetOtherMoney: null,
      suggestOtherMoney: null,
      planOtherMoney: null,
      costOtherMoney: null,
      //生活
      targetLifeMoney: null,
      suggestLifeMoney: null,
      planLifeMoney: null,
      costLifeMoney: null,
      //提升
      targetImproveMoney: null,
      suggestImproveMoney: null,
      planImproveMoney: null,
      costImproveMoney: null,
      addFrom: {},
      sumPlanMoney: null,
      sumGetMoney: null,
      sumCostMoney: null,
      countMoney: null,

      dialogVisible: false,

      salaryId: null,
    };
  },
  mounted() {},
  methods: {
    spanMethod(ref) {
      return ({ row, column, rowIndex, columnIndex }) => {
        const tableRef = this.$refs[ref];
        if (columnIndex === 0 && tableRef.data.length > 0) {
          // 只对第一列进行处理，并且数据不为空
          if (rowIndex > 0) {
            const currentName = row.attributionName;
            const previousRow = tableRef.data[rowIndex - 1];
            const previousName = previousRow.attributionName;
            if (currentName === previousName) {
              // 如果当前行的attributionName与上一行相同，则该行的第一列单元格不显示
              return { rowspan: 0, colspan: 0 };
            } else {
              // 如果当前行的attributionName与上一行不同，则计算合并行数
              let rowspan = 1;
              for (let i = rowIndex + 1; i < tableRef.data.length; i++) {
                if (tableRef.data[i].attributionName === currentName) {
                  rowspan++;
                } else {
                  break;
                }
              }
              // 设置合并行数
              return { rowspan, colspan: 1 };
            }
          } else {
            // 第一行默认是合并状态，计算合并行数
            let rowspan = 1;
            for (let i = 1; i < tableRef.data.length; i++) {
              if (row.attributionName === tableRef.data[i].attributionName) {
                rowspan++;
              } else {
                break;
              }
            }
            return { rowspan, colspan: 1 };
          }
        }
      };
    },
    handleChange(val) {},

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
          // done();
        })
        .catch((_) => {});
    },
    show(row, type) {
      //1调整计划 2填报实际 3查看
      this.selectSalaryByButton(row.version);
      this.dialogVisible = true;
      this.type = type;
      console.log(this.type);
    },
    cancel() {
      console.log(this.$parent.$parent.questionnaireId)
      this.$parent.$parent.questionnaireId=null;
      this.$parent.$parent.id=null;
      this.countMoney = null;
      this.addFrom = {};
      this.salaryId = null;
      this.dialogVisible = false;
      (this.fixDataList = []),
        (this.otherDataList = []),
        (this.improveDataList = []),
        (this.lifeDataList = []),
        //固定
        (this.targetFixMoney = null),
        (this.suggestFixMoney = null),
        (this.planFixMoney = null),
        (this.costFixMoney = null),
        //其他
        (this.targetOtherMoney = null),
        (this.suggestOtherMoney = null),
        (this.planOtherMoney = null),
        (this.costOtherMoney = null),
        //生活
        (this.targetLifeMoney = null),
        (this.suggestLifeMoney = null),
        (this.planLifeMoney = null),
        (this.costLifeMoney = null),
        //提升
        (this.targetImproveMoney = null),
        (this.suggestImproveMoney = null),
        (this.planImproveMoney = null),
        (this.costImproveMoney = null),
        (this.addFrom = {}),
        (this.sumPlanMoney = null),
        (this.sumGetMoney = null),
        (this.sumCostMoney = null);
    },
    //查询详情
    selectSalaryByButton(version) {
      selectSalaryByIdV1({ version: version }).then((res) => {
        this.fixDataList = res.result.fixDataList;
        this.otherDataList = res.result.otherDataList;
        this.improveDataList = res.result.improveDataList;
        this.lifeDataList = res.result.lifeDataList;
        this.addFrom.disposeMonth = res.result.disposeMonth;
        this.countMoney = res.result.countMoney;
        this.recalculate();
      });
    },
    //重新计算
    recalculate() {
      //计算前需要重新设置为0
   //固定
   this.targetFixMoney =0
        this.suggestFixMoney  =0
         this.planFixMoney  =0
         this.costFixMoney  =0
        //其他
         this.targetOtherMoney  =0
         this.suggestOtherMoney  =0
         this.planOtherMoney  =0
         this.costOtherMoney  =0
        //生活
         this.targetLifeMoney  =0
         this.suggestLifeMoney  =0
         this.planLifeMoney  =0
         this.costLifeMoney  =0
        //提升
         this.targetImproveMoney  =0
         this.suggestImproveMoney  =0
         this.planImproveMoney  =0
         this.costImproveMoney  =0
         this.sumPlanMoney  =0
         this.sumCostMoney =0


      if (this.fixDataList?.length) {
        this.fixDataList.forEach((item) => {
          this.targetFixMoney += Number(item.targetMoney )|| 0;
          this.suggestFixMoney += Number(item.suggestMoney )|| 0;
          this.planFixMoney += Number(item.planMoney )|| 0;
          this.costFixMoney += Number(item.costMoney)|| 0;
        });
      }
      if (this.otherDataList?.length) {
        //其他
        this.otherDataList.forEach((item) => {
          this.targetOtherMoney += Number(item.targetMoney )|| 0;
          this.suggestOtherMoney += Number(item.suggestMoney )|| 0;
          this.planOtherMoney += Number(item.planMoney )|| 0;
          this.costOtherMoney += Number(item.costMoney )|| 0;
        });
      }
      if (this.improveDataList?.length) {
        //提升
        this.improveDataList.forEach((item) => {
          this.targetImproveMoney += Number(item.targetMoney  )|| 0;
          this.suggestImproveMoney += Number(item.suggestMoney )|| 0;
          this.planImproveMoney +=Number(item.planMoney  )|| 0;
          this.costImproveMoney +=Number(item.costMoney  )|| 0;
        });
      }
      if (this.lifeDataList?.length) {
        //生活
        this.lifeDataList.forEach((item) => {
          this.targetLifeMoney += Number(item.targetMoney  )|| 0;
          this.suggestLifeMoney += Number(item.suggestMoney  )|| 0;
          this.planLifeMoney += Number(item.planMoney  )|| 0;
          this.costLifeMoney += Number(item.costMoney )|| 0;
        });
      }
      this.sumPlanMoney =
        this.planLifeMoney +
        this.planImproveMoney+
        this.planOtherMoney+
        this.planFixMoney;
      this.sumCostMoney =
        this.costLifeMoney +
        this.costImproveMoney +
        this.costOtherMoney +
        this.costFixMoney;
    },
    refreshDataButton() {
      refreshDataApi({ disposeMonth: this.addFrom.disposeMonth }).then(
        (res) => {
          if (res.result != null) {
            this.countMoney = res.result.countMoney;
            this.salaryId = res.result.id;
          }
        }
      );
    },
    //保存
    analyzeShowButton() {
      var data = {
        salaryId: this.salaryId,
        fixDataList: this.fixDataList,
        otherDataList: this.otherDataList,
        improveDataList: this.improveDataList,
        lifeDataList: this.lifeDataList,
      };
      add(data).then((res) => {
        this.dialogVisible = false;
        this.cancel();
        this.$parent.$parent.getSalaryDetails();
      });
    },
  },
};
</script>
   